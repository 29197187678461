
import { Options, Vue } from "vue-class-component";
import { settingsRef, Settings } from "@/models/Settings";
import { defineAsyncComponent } from "vue";

@Options({
  components: {
    'vue-feather': defineAsyncComponent(() => import('vue-feather')),
  },
  data() {
    return {
      settings: settingsRef
    }
  }
})
export default class Spinner extends Vue {
  settings !: Settings;
}
