/* eslint-disable */
// @ts-nocheck
import { Directive } from "vue";

const debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
const __onResize = function (element) {
  const ctx = element.__ctx;
  const size =
    Math.max(
      Math.min(
        element.clientWidth / (ctx.ratio * 10),
        parseFloat(ctx.maxFontSize)
      ),
      parseFloat(ctx.minFontSize)
    ) + "px";
  element.style.fontSize = size;
};
const defaultOption = {
  delay: 200,
  ratio: 1,
  minFontSize: "16px",
  maxFontSize: "500px"
};
export default {
  mounted(el, binding) {
    const option = binding.value;
    const ctx = {
      ...defaultOption,
      ...option
    };
    el.__ctx = ctx;
    el.__debounceHandler = debounce(function () { __onResize(el) }, ctx.delay);
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", el.__debounceHandler, { passive: true });
    }
    __onResize(el);
  },
  unmounted(el) {
    if (typeof window !== 'undefined') {
      window.removeEventListener("resize", el.__debounceHandler);
    }
  }
} as Directive;
