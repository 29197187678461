
import { Options, Vue } from "vue-class-component";
import Button from "@/components/Button.vue";
import { ProgressFinisher, useProgress } from "@marcoschulte/vue3-progress";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { $BTSTracker, BTSTracker } from "./model";
import router, { routes } from "@/router";
import { isMobile } from "./ts/platform";
import { defineAsyncComponent } from 'vue'
import Connect from './components/Connect.vue'
import { ToastContentType, createToast } from "mosha-vue-toastify";
import { isIOS } from "detect-mobile-device";
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from "@capacitor/core";

@Options({
  components: {
    Footer: defineAsyncComponent(() => import("@/components/Footer.vue")),
    Button,
    Connect,
  },
  data() {
    return {
      btsTracker: $BTSTracker,
      showGpsDialogError: false,
      dialogDimissed: false,
    };
  },
  mounted() {
    this.addTasks();
    this.progressBarStop(); // stoppiamo la progressbar che mostra il caricamento della App
    // avviamo la progressbar durante il cambio della view
    this.$router.beforeEach(
      (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
      ) => {
        this.progressBarStart();
        next();
      }
    );
    // stoppiamo la progressbar quando la view è stata cambiata
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$router.afterEach(
      () => {
        this.progressBarStop();
      }
    );

    const btsTracker = this.btsTracker;
    addEventListener("unload", async () => {
      await btsTracker.disconnect();
    });
    this.$refs["content"].onscroll = () => {
      if (this.$refs["content1"] && this.$refs["content1"].onscroll) {
        this.$refs["content1"].onscroll(this.$refs["content"]);
      }
    };

    setTimeout(() => { // fade out animation of the splash screen

      if (sessionStorage.getItem("updating")) {
        return;
      }

      const preApp = document.getElementById("pre-app");
      const App = document.getElementById("app");
      if (preApp) {
        preApp.style.opacity = "0";
        setTimeout(() => {
          preApp.remove();
        }, 700);
      }
      if (App) App.style.opacity = "1";

    }, 1500);
  },
  create() {
    const progress = useProgress().start(); // avviamo la progressbar mentre carichiamo l'app.
    this.progresses.push(progress);
  },
  beforeMount() {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = '';
    })
  }
})


export default class App extends Vue {
  gpsTask: number | undefined;
  showGpsDialogError = false;
  dialogDimissed = false;
  progresses = Array<ProgressFinisher>();
  clickCounts = 0;
  btsTracker!: BTSTracker;

  progressBarStart() {
    const progress = useProgress().start();
    this.progresses.push(progress);
  }
  progressBarStop() {
    this.progresses.pop()?.finish();
  }

  getTime(diff: number): string {
    let seconds = Math.floor(diff / (1000)); let minutes = 0;
    if (seconds > 60) {
      seconds = Math.floor(diff / 1000) % 60;
      minutes = Math.floor(diff / (1000 * 60));
    }
    return minutes + "m " + seconds + "s ";
  }

  addTasks(): void {

    //console.log("Injecting GPS Task");


    const taskToRun = () => {
      this.gpsTask = setInterval(async () => {
        try {
          // posizione 
          await this.btsTracker.coordinates();

          if (this.btsTracker.gpsBTS4G == 0 && this.btsTracker.gpsBTS5G == 0) {
            if ($BTSTracker.value.checkAccuracy == false && $BTSTracker.value.isConnected() == true) {
              if ($BTSTracker.value.accuracy > 15) {
                createToast({
                  title: this.$t('createToast.warning'),
                  description: this.$t('createToast.accuracy'),
                }, {
                  type: 'warning',
                  showIcon: true,
                  hideProgressBar: true,
                });
              }
              $BTSTracker.value.checkAccuracy = true;
            }
          }

          const today = new Date();
          let day = today.getDate().toString();
          if (day.length == 1) {
            day = '0' + day;
          }
          let month = (today.getMonth() + 1).toString();
          if (month.length == 1) {
            month = '0' + month;
          }
          const date = day + '/' + month + '/' + today.getFullYear();
          let hours = today.getHours().toString();
          if (hours.length == 1) {
            hours = '0' + hours;
          }
          let minutes = (today.getMinutes()).toString();
          if (minutes.length == 1) {
            minutes = '0' + minutes;
          }
          const time = hours + ':' + minutes;
          $BTSTracker.value.deviceDataTime = date + ' ' + time;
          if ($BTSTracker.value.isScanning == false && $BTSTracker.value.staticName == false) {
            $BTSTracker.value.fileName = "log_" + today.getFullYear() + month + day + "_" + hours + minutes;
          }

          if ($BTSTracker.value.isScanning) {
            const currentDate = new Date();
            if ($BTSTracker.value.cellHlte != undefined && $BTSTracker.value.cellHlte.time != undefined) {
              const diff = currentDate.getTime() - $BTSTracker.value.cellHlte.time.getTime();
              $BTSTracker.value.timeLte = this.getTime(diff);
            }
            if ($BTSTracker.value.cellHgsm != undefined && $BTSTracker.value.cellHgsm.time != undefined) {
              const diff = currentDate.getTime() - $BTSTracker.value.cellHgsm.time.getTime();
              $BTSTracker.value.timeGsm = this.getTime(diff);
            }
            if ($BTSTracker.value.cellHumts != undefined && $BTSTracker.value.cellHumts.time != undefined) {
              const diff = currentDate.getTime() - $BTSTracker.value.cellHumts.time.getTime();
              $BTSTracker.value.timeUmts = this.getTime(diff);
            }
          }
          const minutesGSM = Number($BTSTracker.value.timeGsm.split('m')[0]);
          const minutesUMTS = Number($BTSTracker.value.timeUmts.split('m')[0]);
          const minutesLTE = Number($BTSTracker.value.timeLte.split('m')[0]);
          if ($BTSTracker.value.modem == 1 && $BTSTracker.value.restart_hua == false) {
            if ((minutesGSM > 3 && minutesUMTS > 3 && minutesLTE > 3) || (minutesGSM > 6 || minutesUMTS > 6 || minutesLTE > 6)) {
              $BTSTracker.value.sendCommand("restart_hua", { "restart": true });
              //console.log("restart_hua");
              $BTSTracker.value.restart_hua = true;
            }
          }
        } catch (e) {
          //console.error(e);
          clearInterval(this.gpsTask);
        }
      }, 5000);
    }

    if (Capacitor.getPlatform() == "web") {
      taskToRun();
    } else {
      Geolocation.checkPermissions().then((result) => {
        if (result.location == "granted") {
          taskToRun();
        } else if (result.location == 'prompt' || result.location == 'prompt-with-rationale') {
          Geolocation.requestPermissions();
          setTimeout(() => {
            this.addTasks();
          }, 1000);
        } else {
          Geolocation.requestPermissions();
          createToast({
            title: this.$t('createToast.warning'),
            description: this.$t('createToast.gps'),
          }, {
            type: 'warning',
            showIcon: true,
            hideProgressBar: true,
          });
        }
      });
    }

    // task per prendere la posizione





  }

  headerClick() {
    if (process.env.NODE_ENV !== 'production' || document.cookie.includes("**^_^_^_^||scdevmode||^_^_^_^**")) {
      if (this.clickCounts >= 5) {
        return;
      }
      this.clickCounts++;
    }
  }


  jumpRoute(jump: number) {
    if (!isMobile()) {
      return;
    }
    let current = 0;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === this.$route.path) {
        current = i;
        break;
      }
    }
    current += jump;
    if (current < 0) {
      current = 0;
    } else {
      current %= routes.length;
    }
    this.$router.push(routes[current].path);
  }

  isIOS() {
    return isIOS();
  }

}
