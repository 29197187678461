
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import { BTSTracker, $BTSTracker } from "@/model";
import { defineAsyncComponent } from 'vue'
import BTSinfo from "@/components/statusview/BTSinfo.vue";
import { GDialog } from "gitart-vue-dialog";
import Progressbar from "@/components/Progressbar.vue";
import { createToast } from "mosha-vue-toastify";

@Options({
  components: {
    BTSinfo: defineAsyncComponent(() => import("@/components/statusview/BTSinfo.vue")),
    Button: defineAsyncComponent(() => import("@/components/Button.vue")),
    Battery: defineAsyncComponent(() => import("@/components/statusview/Battery.vue")),
    SDCard: defineAsyncComponent(() => import("@/components/statusview/SDCard.vue")),
    SimCard: defineAsyncComponent(() => import("@/components/statusview/SimCard.vue")),
    DeviceTime: defineAsyncComponent(() => import("@/components/statusview/DeviceTime.vue")),
    Satellites: defineAsyncComponent(() => import("@/components/statusview/Satellites.vue")),
    BBox: defineAsyncComponent(() => import("@/components/BBox.vue")),
    RadialProgress: defineAsyncComponent(() => import("vue3-radial-progress")),
    InfoBox: defineAsyncComponent(() => import("@/components/statusview/InfoBox.vue")),
    InitModem: defineAsyncComponent(() => import("@/components/statusview/InitModem.vue")),
    Progressbar: defineAsyncComponent(() => import("@/components/Progressbar.vue")),
  },
  data() {
    const state = ref(false);
    return {
      btsTracker: $BTSTracker,
      charging: false,
      battery: 0,
      sdCardStatus: 0,
      dialogState: state,
      satellites: 0,
      simCardStatus: 0,
    };
  },
  async activated() {
    if (this.isConnected() && this.btsTracker.isScanning == false && this.btsTracker.readyForScanning == true && this.licenseActive == 1) {
      //console.log("activated status");
      await this.btsTracker.updateInfos();
    }
  },
})
export default class StatusView extends Vue {
  btsTracker!: BTSTracker;
  battery!: number;
  charging = false;

  gpsState = 0;

  sdCardStatus = 0;

  satellites = 0;
  device = 0;
  BTS = 0;
  Btsinfo = 0;
  contaSim = 0;
  dialogStateGPS = false;
  dialogStateInfo = false;
  dialogStateSD = false;
  dialogStateSIM = false;
  dialogStateBattery = false;
  dialogStateTime = false;
  dialogNews = false;
  simCardStatus = 0;

  async disconnect() {
    await this.btsTracker.disconnect();
  }

  async updateSDCard() {
    this.btsTracker.caricamentoSD = true;
    await this.btsTracker.updateSDCard();
  }

  // FUNZIONE DI DEBUG PER GLI STATI DELLE ROW
  add(): void {
    this.battery += 5;
    this.satellites++;
    this.sdCardStatus++;
    this.simCardStatus++;
  }
  // FUNZIONE DI DEBUG PER GLI STATI DELLE ROW
  remove(): void {
    this.battery -= 5;
    this.satellites--;
    this.sdCardStatus--;
    this.simCardStatus--;
  }

  myFunction(): void {
    let copyText = "latitude: " + this.btsTracker.latitude.toFixed(5) + "; longitude:" + this.btsTracker.longitude.toFixed(5);
    if (this.BTS == 0 && this.btsTracker.manuallyPosition == false) {
      copyText += "; accuracy: " + this.btsTracker.accuracy.toFixed(3);
    } else if (this.BTS == 1) {
      copyText += "; altitude:" + this.btsTracker.altitude;
    }
    navigator.clipboard.writeText(copyText);
    createToast(
      {
        title: this.$t("createToast.copied"),
        description: copyText,
      }, {
      type: 'success',
      showIcon: true,
      hideProgressBar: true,
    }
    );
  }

  isBts(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.$root.appOrBts == 1;
  }

  isConnected(): boolean {
    if (this.$root) {
      return this.btsTracker.isConnected();
    }
    return false;
  }

  getBTSTracker(): BTSTracker {
    return this.btsTracker;
  }

  getLatitude(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.btsTracker.latitude;
  }
  getLongitude(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.btsTracker.longitude;
  }
  getAccuracy(): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.btsTracker.accuracy;
  }
  getSdCardColor(): string {
    const percentage = this.getSDCardPercentage();
    if (percentage == -1) {
      return "#e84118";
    }
    if (percentage >= 75) { // > red
      return "#e84118";
    }
    if (percentage >= 50) { // > orange
      return "#fbc531";
    }
    return "#10ac84"
  }

  getTime(): number {
    const data = new Date();
    var time = data.getHours();
    var resto = time % 12;
    return resto;
  }

  getBatteryColor(): string {
    if (this.btsTracker.usbConnected) {
      return "#10ac84";
    }
    if (this.btsTracker.mediaBatteria > 70) {
      return "#10ac84";
    } else if (this.btsTracker.mediaBatteria <= 70 && this.btsTracker.mediaBatteria >= 30) {
      return "#fbc531";
    } else if (this.btsTracker.mediaBatteria < 30) {
      return "#e84118";
    }
    return "c-info";
  }


  getInfo(): number {
    if (this.btsTracker.serial == "") {
      this.Btsinfo = 0;
      return 0;
    } else {
      this.Btsinfo = 1;
      return 1;
    }
  }

  getInfoColor(): string {
    if (this.btsTracker.licenseActive != 1) {
      return "#FF0000";
    } else {
      return "#10ac84";
    }
  }

  getGPSPercentage(): number {
    if (this.btsTracker.tipo == 0) { // niente gps
      this.gpsState = 0;
      this.satellites = 0;
      this.device = 0;
      this.BTS = 0;
    } else if (this.btsTracker.tipo == -1) {
      this.gpsState = 1;
      this.satellites = 0;
      this.device = 1;
      this.BTS = 0;
    } else if (this.btsTracker.tipo == 1) {
      this.gpsState = 2;
      this.satellites = 1;
      this.device = 1;
      this.BTS = 1;
    } else if (this.btsTracker.latitude == undefined || this.btsTracker.longitude == undefined || this.btsTracker.altitude == undefined) {
      this.gpsState = 0;
      this.satellites = 0;
      this.device = 0;
    }
    return this.gpsState;
  }

  getSDCardPercentage(): number {
    if (this.btsTracker.valid == -1) {
      this.sdCardStatus = 0;
      return 0;
    } else if (this.btsTracker.sdCardError == true) {
      this.sdCardStatus = 0;
      return -1;
    } else {
      this.sdCardStatus = 1;
      return ((this.btsTracker.sdCardUsedSize * 100) / this.btsTracker.sdCardTotalSize);
    }

  }

  getSDstatus(): number {
    if (this.btsTracker.sdCardTotalSize == 0 || this.btsTracker.sdCardError == true) {
      return 0;
    } else {
      return 1;
    }
  }

  getSimPercentage(): number {
    if (this.btsTracker.numSim == 0) {
      this.simCardStatus = 0;
    } else {
      this.simCardStatus = 1;
    }
    return this.btsTracker.numSim;
  }

  deviceDateInfo(): number {
    let deviceDate = new Date(this.btsTracker.deviceTime);

    const today = new Date();
    let day = today.getDate().toString();
    if (day.length == 1) {
      day = '0' + day;
    }
    let month = (today.getMonth() + 1).toString();
    if (month.length == 1) {
      month = '0' + month;
    }
    const date = day + '/' + month + '/' + today.getFullYear();
    let hours = today.getHours().toString();
    if (hours.length == 1) {
      hours = '0' + hours;
    }
    let minutes = (today.getMinutes()).toString();
    if (minutes.length == 1) {
      minutes = '0' + minutes;
    }
    const time = hours + ':' + minutes;
    this.btsTracker.deviceDataTime = date + ' ' + time;
    if (deviceDate.getDate() == today.getDate() &&
      deviceDate.getMonth() == today.getMonth() &&
      deviceDate.getFullYear() == today.getFullYear() &&
      deviceDate.getHours() == today.getHours()) {
      return 2;
    }
    return 1;
  }

  getPrettySize(s: number): string { // prettify size
    const size = s / 1024;
    if (size > 1024 * 1024 * 1024) {
      return (size / (1024 * 1024 * 1024)).toFixed(1) + " TB";
    } else if (size > 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(1) + " GB";
    } else if (size > 1024) {
      return (size / 1024).toFixed(1) + " MB";
    } else {
      return size.toFixed(1) + " KB";
    }
  }

  isScanning(): string {
    if (this.btsTracker.isScanning) {
      return "isScanning";
    } else {
      return "";
    }
  }
}
