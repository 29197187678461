import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withMemo as _withMemo, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-065a3f05"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "fa-solid fa-question fa-beat",
  style: {"color":"#e93f3f"}
}
const _hoisted_3 = {
  key: 1,
  class: "fa-solid fa-question"
}
const _hoisted_4 = {
  ref: "content",
  class: "content"
}
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_progress_bar = _resolveComponent("vue3-progress-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Connect = _resolveComponent("Connect")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vue3_progress_bar),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("header", {
          class: "header",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.headerClick()))
        }, [
          _withMemo([_ctx.$t(_ctx.$route.name as string)], () => (_openBlock(), _createElementBlock("span", null, [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.$route.name as string)), 1)
          ])), _cache, 0),
          (_ctx.btsTracker.isConnected())
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                type: "button",
                class: "btn version-btn",
                href: "https://securcube.net/bts-tracker-5g-user-manual/",
                target: "_blank",
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: _ctx.$t('news')
              }, [
                (_ctx.btsTracker.version.indexOf(_ctx.btsTracker.lastVersionF) < 0)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                  : (_openBlock(), _createElementBlock("i", _hoisted_3))
              ], 8, _hoisted_1))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, _ctx.$i18next.isInitialized]
        ])
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      (_ctx.$route.name === 'header.settings' || _ctx.btsTracker.connected)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "content1" }, null, 512))
              ], 1024))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _withDirectives((_openBlock(), _createBlock(_KeepAlive, null, [
        _createVNode(_component_Connect)
      ], 1536)), [
        [_vShow, _ctx.$route.name !== 'header.settings' && !_ctx.btsTracker.connected]
      ])
    ], 512), [
      [_vShow, _ctx.$i18next.isInitialized]
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          _withMemo([_ctx.$t(_ctx.$route.name as string)], () => _createVNode(_component_Footer), _cache, 2)
        ], 512), [
          [_vShow, _ctx.$i18next.isInitialized]
        ])
      ]),
      _: 1
    })
  ], 64))
}