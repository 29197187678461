import { ref } from "vue";
import { Log } from "./Log";

export class Settings {
  /* SCAN */
  saveScansLocally = true;
  improveAccuracy = true;

  /* PERFORMANCE */
  animations = true;
  blur = true;

  /* OTHER */
  country = "";

  constructor() {
    if (localStorage) {
      const settingsString = localStorage.getItem("settings");
      if (settingsString) {
        const s = JSON.parse(settingsString);
        // iterate s keys
        for (const key in s) {
          if (Reflect.has(this, key)) { // check if key is in this class
            Reflect.set(this, key, s[key]); // update this class instance value at key which is the variable name
          }
        }

      } else { // no settings - init default
        localStorage.setItem("settings", JSON.stringify(this));
      }
    } else {
      Log.error("localStorage not available");
    }
  }
}

export const settings = new Proxy(new Settings(), // proxy for automatically saving settings
  {
    get(target, prop, receiver) {
      Log.debug("Reading value of " + prop.toString());
      return Reflect.get(target, prop); // just return value
    },
    set(obj, prop, value) {
      Reflect.set(obj, prop, value); // set the variable
      Promise.resolve(localStorage.setItem("settings", JSON.stringify(obj))); // save the settings to localStorage
      Log.info("Settings updated");
      return true;
    }
  }
);

export const settingsRef = ref(settings)