export class SortingPreferences {
  constructor(n: string) {
    this.name = n;
  }
  name = ""
  sortById = 0;
  ascending = false;
  carriers: string[] = [];
  technologies = ['LTE', 'UMTS', 'GSM', 'AutoConnection5G'];
}

export class RawSortingPreferences {
  name = "";
  sortById = 0;
  ascending = false;
  carriers = "";
  technologies = "LTE;UMTS;GSM;AutoConnection5G";
}