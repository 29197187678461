
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    type: {
      type: String,
      default: "info",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Button extends Vue {
  type!: string;
  isDisabled!: boolean;
}
